import CompProCard from "@@/after_sale/pro_card.vue";
import { mdaftersaleUploadExpress } from "@/api/index";
import MDRouter from "@/service/router";
import { mapState, mapActions } from "vuex";
import { mdToast } from "../../utils/tools";
export default {
  components: {
    CompProCard
  },
  computed: {
    ...mapState({
      expressCompanyList: state => state.order.expressCompanyList.map(item => ({
        text: item,
        value: item
      }))
    })
  },
  data() {
    return {
      aftersale_id: '',
      express_no: '',
      // 物流单号
      express_company: '',
      // 物流公司
      pro_info: {},
      showPop: false,
      selectedValues: []
    };
  },
  mounted() {
    const query = this.$route.query;
    this.aftersale_id = query.aftersale_id;
    this.pro_info = {
      pro_id: query.pro_id,
      name: query.name,
      url: query.url
    };
    // 获取物流地址
    if (this.expressCompanyList == 0) {
      this.GetExpressCompanyList();
    }
  },
  methods: {
    ...mapActions(['GetExpressCompanyList']),
    showList() {
      this.showPop = true;
    },
    confirm({
      selectedValues
    }) {
      this.express_company = selectedValues[0];
      this.showPop = false;
    },
    uploadExpress() {
      if (this.express_no && this.express_company) {
        mdaftersaleUploadExpress({
          aftersale_id: this.aftersale_id,
          express_no: this.express_no,
          express_company: this.express_company
        }).then(res => {
          if (res.status == 0) {
            MDRouter.GoBack();
          } else {
            mdToast(res.message);
          }
        });
      }
    }
  }
};