import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5cdaa4ad"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "logistics-add-wrap"
};
const _hoisted_2 = {
  class: "pro-box"
};
const _hoisted_3 = {
  class: "logisitics-add"
};
const _hoisted_4 = {
  class: "bottom-btn"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CompProCard = _resolveComponent("CompProCard");
  const _component_van_field = _resolveComponent("van-field");
  const _component_van_cell_group = _resolveComponent("van-cell-group");
  const _component_van_picker = _resolveComponent("van-picker");
  const _component_van_popup = _resolveComponent("van-popup");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [$data.pro_info.pro_id && $data.pro_info.name && $data.pro_info.url ? (_openBlock(), _createBlock(_component_CompProCard, {
    key: 0,
    pro_info: {
      pro_id: $data.pro_info.pro_id,
      name: $data.pro_info.name,
      url: $data.pro_info.url
    }
  }, null, 8, ["pro_info"])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_van_cell_group, null, {
    default: _withCtx(() => [_createVNode(_component_van_field, {
      modelValue: $data.express_no,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.express_no = $event),
      label: "物流单号",
      placeholder: "请填写物流单号",
      clearable: "",
      "label-width": 100,
      "input-align": "right"
    }, null, 8, ["modelValue"]), _createVNode(_component_van_field, {
      modelValue: $data.express_company,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.express_company = $event),
      label: "物流公司",
      placeholder: "请填写物流公司",
      "label-width": 100,
      "input-align": "right",
      readonly: "",
      "is-link": "",
      onClick: $options.showList
    }, null, 8, ["modelValue", "onClick"])]),
    _: 1
  })]), _createElementVNode("div", _hoisted_4, [_createElementVNode("strong", {
    class: _normalizeClass(['btn', {
      'active': $data.express_no && $data.express_company
    }]),
    onClick: _cache[2] || (_cache[2] = (...args) => $options.uploadExpress && $options.uploadExpress(...args))
  }, "提交申请", 2)]), _createVNode(_component_van_popup, {
    show: $data.showPop,
    "onUpdate:show": _cache[4] || (_cache[4] = $event => $data.showPop = $event),
    round: "",
    position: "bottom",
    "safe-area-inset-bottom": ""
  }, {
    default: _withCtx(() => [_createVNode(_component_van_picker, {
      columns: _ctx.expressCompanyList,
      "visible-option-num": "5",
      onConfirm: $options.confirm,
      onCancel: _cache[3] || (_cache[3] = $event => $data.showPop = false)
    }, null, 8, ["columns", "onConfirm"])]),
    _: 1
  }, 8, ["show"])]);
}